import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@services/modal.service';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import panzoom, { PanZoom } from 'panzoom';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonContent, IonButton, IonIcon } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CreateSourceStandalonePipe,
    IonContent,
    IonButton,
    IonIcon,
  ],
  template: `
    <ion-content [scrollY]="false" [scrollX]="false">
      <ion-button
        class="absolute top-1 right-1 z-10"
        size="large"
        fill="clear"
        (click)="dismiss()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon
      ></ion-button>
      <img #img class="max-w-none" [src]="src" />
    </ion-content>
  `,
  styles: [
    `
      ion-icon {
        color: white;
      }
    `,
  ],
})
export class ImgModal implements AfterContentInit, OnDestroy {
  @Input() src: string;
  @ViewChild('img', { static: true }) img: ElementRef<HTMLImageElement>;
  panzoomInstance: PanZoom | undefined;

  constructor(private modalService: ModalService) {
    addIcons({ closeOutline });
  }

  ngAfterContentInit(): void {
    this.img.nativeElement.onload = async () => {
      let imgWidth = this.img.nativeElement.width;
      let imgHeight = this.img.nativeElement.height;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      var widthRatio = imgWidth / windowWidth;
      var heightRatio = imgHeight / windowHeight;
      var resizeRatio = Math.max(widthRatio, heightRatio);
      // console.log({
      //   imgWidth,
      //   imgHeight,
      //   windowHeight,
      //   windowWidth,
      //   resizeRatio,
      //   heightRatio,
      //   widthRatio,
      // });
      if (resizeRatio > 1) {
        this.img.nativeElement.width = imgWidth / resizeRatio;
        this.img.nativeElement.height = imgHeight / resizeRatio;
      }
      imgWidth = this.img.nativeElement.width;
      imgHeight = this.img.nativeElement.height;
      // console.log({
      //   imgWidth,
      //   imgHeight,
      // });
      const initialX = windowWidth / 2 - imgWidth / 2;
      const initialY = windowHeight / 2 - imgHeight / 2;
      this.panzoomInstance = panzoom(this.img.nativeElement, {
        maxZoom: 3,
        minZoom: 1,
        initialZoom: 1,
        onTouch: this.onOutsideClick.bind(this),
        onClick: this.onOutsideClick.bind(this),
      });
      this.panzoomInstance.moveTo(initialX, initialY);
    };
  }

  async onOutsideClick(e: TouchEvent | Event) {
    // e.stopPropagation();
    const target = e.target as HTMLElement;
    const isDismissing = await this.dismissing;
    if (!['IMG'].includes(target.tagName) && !isDismissing) {
      this.dismiss();
    }
    return false;
  }
  dismissing: Promise<boolean> = Promise.resolve(false);

  ngOnDestroy() {
    this.panzoomInstance?.dispose();
  }
  dismiss() {
    this.dismissing = this.modalService.dismiss();
  }
}
